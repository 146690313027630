<template>
	<div class="mobile-footer-nav">
		<div class="mobile-footer-nav-item">
			<div @click="triggerListen" class="non-a">
				<div><img class="footer-icon" src="/public-static/icons/listen.png" /></div>
				<span class="title">Listen</span>
			</div>
		</div>
		<div class="mobile-footer-nav-item">
			<a v-if="store.metroDomains.indexOf(store.domain) === -1" href="/news" data-targpagetpl="aggregate-news">
				<div><img class="footer-icon" src="/public-static/icons/news.png" /></div>
				<span class="title">News</span>
			</a>
			<a v-else href="/community" data-targpagetpl="aggregate-events">
				<div><img class="footer-icon" src="/public-static/icons/news.png" /></div>
				<span class="title">Community</span>
			</a>
		</div>
		<div class="mobile-footer-nav-item">
			<a href="/shows" data-targpagetpl="aggregate-shows">
				<div><img class="footer-icon" src="/public-static/icons/shows.png" /></div>
				<span class="title">Shows</span>
			</a>
		</div>
		<div class="mobile-footer-nav-item">
			<a href="/win" data-targpagetpl="aggregate-comps">
				<div><img class="footer-icon" src="/public-static/icons/win.png" /></div>
				<span class="title">Win</span>
			</a>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
export default {
	name: "FooterMobileNav",
	components: {},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
		}
	},
	computed: {},
	methods: {
		triggerListen() {
			this.store.fireplaypause++
		},
	},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.mobile-footer-nav {
	display: none;
}
.footer-icon {
	height: 20px;
	margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
	.mobile-footer-nav {
		display: flex;
		position: fixed;
		bottom: 0;
		z-index: 2;
		width: 100%;
		justify-content: space-between;
		box-shadow: 0 0 10px 3px rgb(0 0 0 / 10%);
	}
	.mobile-footer-nav-item {
		width: 25%;
		text-align: center;
	}
	.mobile-footer-nav-item a, .non-a {
		display: block;
		background-color: #FFF;
		width: 100%;
		height: 100%;
		padding: 14px 0 10px;
	}
	.mobile-footer-nav-item a .title, .non-a .title {
		display: block;
		text-transform: uppercase;
		font-size: 11px;
	}
}
</style>